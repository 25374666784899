import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom"
import {
    getAuth,
    onAuthStateChanged,
    signOut
} from 'firebase/auth';
import styles from './Params.module.scss';
import axios from 'axios';

axios.defaults.withCredentials = true;

export default function Params(props) {
    const auth = getAuth();

    const [authUser, setAuthUser] = useState();

    async function portalButton() {
        const location = window.location.href.substring(0, window.location.href.lastIndexOf("/"));
        const location_for_query = ((location === "http://localhost:3000/") ? "http://localhost:5000" : location);
        axios.post(location_for_query + "/create-portal-session",
            {
                "idToken": props.idToken,
            },
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            }
        ).then(response => {
            window.location.replace(response.data.url);
        })
    }

    const handlelogout = () => {
        const location = window.location.href.substring(0, window.location.href.lastIndexOf("/"));
        window.location.replace(location);
        signOut(auth);

    }
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setAuthUser(user);
            }
            else {
                setAuthUser(null);
            }
        })
    },
        [])
    return (
        <div>
            <ul className={styles["params-list"]}>
                <li className="navbar"> Logged in as {auth.currentUser.email}</li>
                <li>
                    {props.isSubscriber ?
                        <a href={props.urlPortal}>Manage your Billing</a> : <Link to="/subscription"><button type="button">Subscribe!</button></Link>
                    }
                </li>
                <li><button onClick={handlelogout}>Sign Out</button></li>
            </ul>
        </div >
    )
}