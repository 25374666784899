import React, { useContext } from 'react'
import styles from "./Elements.module.css"

export default function Element(props) {
    if (props.total_length === 0) {
        return <b>{props.elt}</b>
    }

    if (props.total_length - 1 === props.index) {
        if (props.elt != "") {
            return <li className={styles.youtube_url}><b>Global summary:{props.elt}</b> </li>
        }
        else {
            return <></>
        }
    }
    function printable_version(number) {
        return (number < 10 ? "0" + number : number);
    }
    function visual_timing(index) {
        const string_seconds = "00"
        const minutes = index % 12
        const hours = Math.floor(index / 12);

        const string_minutes = (minutes > 0 ? printable_version(minutes * 5) + ":" : "00:");
        const string_hours = (hours > 0 ? printable_version(hours) + ":" : "");
        return string_hours + string_minutes + string_seconds;
        /*
        if it's 0 then it should look like -> 00:00
        1 -> 05:00
        2 -> 10:00
        ...
        12 -> 01:00:00
        13 -> 01:05:00
        */
        if (index === 0) {
            return "00:00"
        }
        else if (index === 1) {
            return "05:00"
        }
        else if (index >= 2 && index < 12) {
            return index * 5 + ":00"
        }
        else if (index >= 12) {
            return visual_timing(index - 10) + "00:00"
        }
    }
    const timer_init = 5 * props.index
    const timer_final = 5 * (props.index + 1)
    //https://youtu.be/1MmbO2i5g14?t=27
    //https://www.youtube.com/watch?v=1MmbO2i5g14&t=27s
    return (<li className={styles.youtube_url}><b>[<a className={styles.youtube_url} href={props.url + "&t=" + timer_init * 60 + "s"} target="_blank">{visual_timing(props.index)}</a> : <a className={styles.youtube_url} href={props.url + "&t=" + timer_final * 60 + "s"} target="_blank">{visual_timing(props.index + 1)}</a>]</b>{props.elt}</li>)
}