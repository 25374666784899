import React, { useState } from "react";
import {
    getAuth,
    signInWithEmailAndPassword,
    signInWithEmailLink,
    createUserWithEmailAndPassword,
    sendSignInLinkToEmail,
    isSignInWithEmailLink,
    sendEmailVerification,
    GoogleAuthProvider,
    signInWithPopup
} from 'firebase/auth';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { FirebaseError } from '@firebase/util';
import styles from './Signin.module.scss';
import { useNavigate, useSearchParams } from "react-router-dom";

export default function SignIn() {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    const [searchParams, setSearchParams] = useSearchParams();
    searchParams.get("subscribe");
    // To apply the default browser preference instead of explicitly setting it.
    auth.useDeviceLanguage();
    const [user, setUser] = useState({})
    const actionCodeSettings = {
        url: window.location.origin + "/",
        handleCodeInApp: true,
    };
    let navigate = useNavigate();
    const subscribe = searchParams.get('subscribe');
    const url_to_navigate = !!subscribe ? '/subscription' : '/';
    async function signUp() {
        try {
            await createUserWithEmailAndPassword(auth, user.emailSignUp, user.passwordSignUp);
            await createUser();
            navigate(url_to_navigate);
        }
        catch (err) {
            if (err instanceof FirebaseError && err.code === "auth/email-already-in-use") {
                alert("This e-mail is already in use!");
            }
            else {
                alert("Unknown Error. Please try again.");
            }
        }
    }

    async function getIdToken() {
        try {
            return auth.currentUser.getIdToken(true);
        } catch (error) {
            console.log(error);
            return ""
        }
    }

    async function createUser() {
        const location = window.location.href.substring(0, window.location.href.lastIndexOf("/"));
        const idTokenData = await getIdToken();
        const response = await axios.post(location + "/create-user",
            {
                "idToken": idTokenData,
            },
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            }
        )
    }

    const handleInputs = (event) => {
        let inputs = { [event.target.name]: event.target.value }
        setUser({ ...user, ...inputs });
    }

    const signInUser = () => {
        signInWithEmailAndPassword(auth, user.emailSignIn, user.passwordSignIn).then(
            (creds) => {
                navigate(url_to_navigate);
            }
        ).catch((err) => {
            if (err.code === "auth/wrong-password") {
                alert("Wrong password!");
            }
            else if (err.code === "auth/user-not-found") {
                alert("You are not signed up yet!");
            }
            else {
                alert("Unexpected Error. Please try again.");
            }
            const errorCode = err.code;
            const errorMessage = err.message;
        })
    }
    const authenticateGoogle = () => {
        signInWithPopup(auth, provider).then(
            (result) => {
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                const user = result.user;
                createUser();
                navigate(url_to_navigate);
            })
            .catch((error) => {
                console.log(error);
                alert("Unexpected Error. Please try again.");
            })
    }

    return (<div className={styles.signIn}>
        <div className={styles.rect}>
            <h3>Sign-in with Google...</h3>
            <link rel="stylesheet" type="text/css" href="//fonts.googleapis.com/css?family=Open+Sans" />
            <div className={styles.google_btn} onClick={authenticateGoogle}>
                <div className={styles.google_icon_wrapper}>
                    <img className={styles.google_icon} src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" />
                </div>
                <p className={styles.btn_text}><b>Sign in with google</b></p>
            </div>
        </div>
        <div className={styles.rect}>
            <h3>...Or with your E-mail</h3>
            <input className={styles.inputSignin}
                placeholder="Email"
                name="emailSignIn"
                type="email"
                onChange={event => handleInputs(event)}
            />
            <input
                placeholder="Password"
                name="passwordSignIn"
                type="password"
                onChange={event => handleInputs(event)}
            /><br />
            <button onClick={signInUser}><div className={styles.text}>Sign In</div></button>
        </div>

        <div className={styles.rect}>
            <br />
            <h3>Not registered yet?</h3>
            <input
                placeholder="Email"
                name="emailSignUp"
                type="email"
                onChange={event => handleInputs(event)}
            />
            <input
                placeholder="Password"
                name="passwordSignUp"
                type="password"
                onChange={event => handleInputs(event)}
            />
            <br />
            <button className={styles.button} onClick={signUp}>
                <div className={styles.text}>Sign Up</div></button>
        </div>
    </div>)
}