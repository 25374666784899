import React from "react";
import axios from 'axios';
import { Link } from "react-router-dom"
import styles from './Subscription.module.scss';

axios.defaults.withCredentials = true;
export default function Subscription(props) {
    async function checkoutSession() {
        window.location.replace(props.urlSubscribe);
    }
    return (
        <div>
            <div className={styles["subs-list"]}>
                <h1>Subscription Benefits</h1>
                <ul>
                    <li>3 summarization per week to 30 summarization per day.</li>
                    <li>Early access to all new features.</li>
                    <li>Personal Customer Support, <a href="https://www.linkedin.com/in/paul-darius-sarmadi-715035a4/">you can directly contact me here.</a></li>
                </ul>
                {props.isSubscriber ? <Link to="/"><button>You've already subscribed. Go back to the main page!</button></Link>
                    :
                    props.isSignedIn ? <button onClick={checkoutSession}>Subscribe now for just $2.99/month!</button> :
                        <Link to="/signin?subscribe=true">
                            <button type="button">Sign Up and then Subscribe!</button></Link>}
            </div>
        </div >
    )
}