import React, { useState, useEffect } from 'react';
import styles from "./Home.module.scss";
// import commonStyles from "../CssCommon/CssCommon.module.scss";

import {
    getAuth
} from 'firebase/auth';
import Element from '../Elements/Elements';
import { Link } from "react-router-dom";
import SubscriptionSuccess from '../SubscriptionSuccess/SubscriptionSuccess';
import axios from 'axios';
// Make sure axios sends all the cookies to the backend.
axios.defaults.withCredentials = true;



export default function Home() {
    const auth = getAuth();
    const [textToPrint, setTextToPrint] = useState([]);
    const [videoInfo, setVideoInfo] = useState({ "language": "English" });

    let [success, setSuccess] = useState(false);
    let [sessionId, setSessionId] = useState('');

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get('success')) {
            setSuccess(true);
            setSessionId(query.get('session_id'));
        }

        if (query.get('canceled')) {
            setSuccess(false);
            // "Order canceled -- continue to shop around and checkout when you're ready."
        }
    }, [sessionId]);

    async function getIdToken() {
        try {
            return auth.currentUser.getIdToken(true);
        } catch (error) {
            console.log(error);
            return ""
        }
    }

    async function summarize() {
        const idToken = await getIdToken();
        setTextToPrint(["Loading... Waiting a few seconds..."]);
        /////////////////////////////////////////////
        // Now sending the request to the backend. //
        /////////////////////////////////////////////

        // in localhost: replace location with "http://localhost:5000"
        //if location.href === "localhost:3000"
        try {
            const location = window.location.href;
            const location_for_query = ((location === "http://localhost:3000/") ? "http://localhost:5000/" : location);
            const response = await axios.post(location_for_query + "/summarize",
                {
                    "url": videoInfo.video_url,
                    "language": videoInfo.language,
                    "idToken": idToken
                },
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                }
            )
            setTextToPrint(response['data']);
        } catch (error) {
            const response = ["Unexpected Error! Please retry."];
            setTextToPrint(response);
        }
    }
    const handleInputs = (event) => {
        let inputs = { [event.target.name]: event.target.value };
        setVideoInfo({ ...videoInfo, ...inputs });
    }
    return (
        <div className={styles.home} >
            {success ? <SubscriptionSuccess sessionId={sessionId} /> : <></>}
            <h1 className={styles.home__title}>Youtube Video Summarizer, powered by <i>ChatGPT's AI</i>.</h1>
            <input className={styles.home__input} onChange={event => handleInputs(event)} type="text" name="video_url" placeholder="Paste YouTube link here"></input>
            <div className={styles.chooseLanguage}>
                <h2 className={styles.chooseLanguage__title}>Choose language for summary:</h2>
                <select className={styles.chooseLanguage__select} defaultValue="English" name="language" onChange={event => handleInputs(event)}>
                    <option value="English">English</option>
                    <option value="French">French</option>
                    <option value="German">German</option>
                </select>
            </div>
            <button className={styles["home__summarize-button"]} onClick={summarize}>Summarize it!</button>
            {
                textToPrint.length <= 1 ? textToPrint : <ul className={styles["home__obtained-text"]}>{textToPrint.map(
                    (elt, index) => { return (<Element elt={elt} index={index} total_length={textToPrint.length} url={videoInfo.video_url} />); })}</ul>
            }
            <h2 className={styles.home__footer}>You think my app is great? <a href="https://www.buymeacoffee.com/pauldarius">Buy me a coffee!</a><br /><br />
                You want unlimited access?<br />
                <Link to="/subscription">Subscribe here</Link> to turn 3 free weekly summaries into 30 summaries per day. You'll also get early access to all the new features I'm developing.</h2>
        </div >
    )
}