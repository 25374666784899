import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import {
    getAuth,
    onAuthStateChanged,
    signOut,
} from 'firebase/auth';

// Apparemment ce sera comme ça et c'est tout.
// https://github.com/css-modules/css-modules
import './Navbar.scss';

export default function Navbar(props) {
    const auth = getAuth();
    const [authUser, setAuthUser] = useState();
    const [isDisplayed, setIsDisplayed] = useState(false);
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setAuthUser(user);
            }
            else {
                setAuthUser(null);
            }
        })
    },
        [])
    const handlelogout = () => {
        signOut(auth);
    }

    function handleClickButton() {
        setIsDisplayed(!isDisplayed);
        if (!isDisplayed) {
            // To print the navbar
            document.getElementsByClassName("nav")[0].style.display = 'block';
            document.getElementsByClassName("menu-icon2")[0].style.transform = "rotate(45deg)";
            document.getElementsByClassName("menu-icon3")[0].style.transform = "rotate(-45deg)";
            document.getElementsByClassName("menu-icon")[0].style.backgroundColor = "transparent";
        }
        else {
            // To hide the navbar
            document.getElementsByClassName("nav")[0].style.display = 'none';
            // To turn the burger back to its initial form
            document.getElementsByClassName("menu-icon")[0].style.backgroundColor = "white";
            document.getElementsByClassName("menu-icon2")[0].style.transform = "translate(0px, 12px)";
            document.getElementsByClassName("menu-icon3")[0].style.transform = "translate(0px, -12px)";
        }
    }
    return (
        <nav className="navbar">
            <section className="header-title-line">
                <h1><Link to="/">Video Summarizer</Link></h1>
                <button className="menu-button" onClick={handleClickButton}>
                    <div className="menu-icon3"></div>
                    <div className="menu-icon"></div>
                    <div className="menu-icon2"></div>
                </button>
            </section>
            <nav className='nav'>
                <ul>
                    <li className="title"><Link to="/">Video Summarizer</Link></li>
                    <li className="menu"><Link to="/about">About</Link></li>
                    {props.isSubscriber === undefined ? <></> : props.isSubscriber == true ? <></> : <li className="menu"><Link to="/subscription">Subscribe!</Link></li>}
                    {props.isSignedIn === undefined ? <></> : props.isSignedIn ? <li className="menu"><Link to="/params">Parameters</Link></li> : <li className='menu'><Link to="/signIn">Sign In/Up</Link></li>}
                </ul >
            </nav>
        </nav >
    )
}
