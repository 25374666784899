
import React, { useState, useEffect, useContext } from "react";
import ReactDOM from "react-dom/client";
import {
  Routes,
  Route

} from "react-router-dom";

import { LoggedInContext } from "./UserContext";
import axios from 'axios';
import Cookies from 'universal-cookie';


import './App.scss';


import Home from "./components/Home/Home";
import Navbar from "./components/Navbar/Navbar";
import SignIn from "./components/SignIn/SignIn";
import About from "./components/About/About";
import Params from "./components/Params/Params";
import Subscription from "./components/Subscription/Subscription";
import { v4 as uuidv4 } from 'uuid';

import {
  getAuth, getIdToken, onAuthStateChanged
} from 'firebase/auth';
import { GoogleOAuthProvider } from '@react-oauth/google';

//import SignIn from "./SignIn";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check");

//import { getAnalytics } from "firebase/analytics";
//import { getFirestore } from "firebase/firestore";
//import Root from "./routes/root";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyAqhp2Z63L-PHBsOT0WFYR3JjU3nXVJl2Q",
  authDomain: "summarizer-379211.firebaseapp.com",
  projectId: "summarizer-379211",
  storageBucket: "summarizer-379211.appspot.com",
  messagingSenderId: "1047804819714",
  appId: "1:1047804819714:web:223590ee652610def72a1b",
  measurementId: "G-H66TXRWP7F"
};

const app = initializeApp(firebaseConfig);


function App() {
  const auth = getAuth();
  const [isSignedIn, setIsSignedIn] = useState();
  const [isSubscriber, setIsSubscriber] = useState();
  const [idToken, setIdToken] = useState();
  const [urlSubscribe, setUrlSubscribe] = useState("");
  const [urlPortal, setUrlPortal] = useState("");
  async function getIsSubscriber(idToken) {
    const location = window.location.href.substring(0, window.location.href.lastIndexOf("/"));
    const location_for_query = ((location === "http://localhost:3000/") ? "http://localhost:5000/" : location);
    const response = await axios.post(location_for_query + "/subscriber",
      {
        "idToken": idToken
      },
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      });
    return response.data;
  }

  async function getIdToken() {
    try {
      return auth.currentUser.getIdToken(true);
    } catch (error) {
      console.log(error);
      return ""
    }
  }

  async function getUrlToSubscribe(idToken) {
    const location = window.location.href.substring(0, window.location.href.lastIndexOf("/"));
    const response = await axios.post(location + "/create-checkout-session",
      {
        "idToken": idToken,
      },
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      }
    )
    return response.data.url;
  }

  async function getUrlPortal(idToken) {
    const location = window.location.href.substring(0, window.location.href.lastIndexOf("/"));
    const response = await axios.post(location + "/create-portal-session",
      {
        "idToken": idToken
      },
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      });
    return response.data.url;
  }

  useEffect(() => {
    onAuthStateChanged(auth, async user => {
      // If the user is signed in:
      // I get whether or not the user subscribed.
      // If he subscribed I get a portal link.
      // If he is not subscribed, I get a session link.
      // If the user is not signed in:
      // I remove a potential signedInId that is stored.
      // 
      // I DON'T get a subscriber URL for the user
      // BECAUSE HE SHOULD CONNECT PRIOR TO BUY.
      // If the user is not signed in:
      // I set the id Token to the value of the cookie if exists.
      // If if does not exist, I create the cookie and set idToken to
      // the value of the cookie.
      // I set the value of isSubscriber to false.
      // I set the value of isSignedIn to false.

      // If the user is not signed in
      // The urlsubscribe should redirect to the signin button
      if (user) { // if the user is signed in
        // const cookies = new Cookies();
        // cookies.set("signedInId", user.uid, { path: "/" });
        setIsSignedIn(true);
        const idTokenData = await getIdToken();
        setIdToken(idTokenData);
        const isSubscriberData = await getIsSubscriber(idTokenData);
        setIsSubscriber(isSubscriberData);
        if (isSubscriberData) {
          const urlPortal = await getUrlPortal(idTokenData);
          setUrlPortal(urlPortal);
        }
        else {
          const sessionUrl = await getUrlToSubscribe(idTokenData);
          setUrlSubscribe(sessionUrl);
        }

      }
      else { // the user is signed out or was never signed in.
        // First we got a signedIn state set to false
        setIsSignedIn(false);

        // We remove any cookie that may have identified the user if they were ever signed in.
        const cookies = new Cookies();
        // if the user still has a signedIn cookie from a previous session.
        // we remove the cookie
        // if the unsigned cookie is empty
        if (cookies.get("unsignedId") === undefined) {
          // the user is not signed in, we set an unsigned cookie for him.
          cookies.set("unsignedId", uuidv4(), { path: "/" });
        }
        setIsSubscriber(false);
      }
    })
  },
    []);
  return (
    <GoogleOAuthProvider clientId="1047804819714-7d87dpjdqorerp0oaf56uovslupuvqhu.apps.googleusercontent.com">
      <Navbar isSignedIn={isSignedIn} isSubscriber={isSubscriber} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/about" element={<About />} />
        <Route path="/params" element={<Params idToken={idToken} isSubscriber={isSubscriber} urlPortal={urlPortal} urlSubscribe={urlSubscribe} />} />
        <Route path="/subscription" element={<Subscription isSignedIn={isSignedIn} isSubscriber={isSubscriber} urlSubscribe={urlSubscribe} />} />
      </Routes>
    </GoogleOAuthProvider>
  )
}

export default App;
